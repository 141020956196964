import React, { useState, useEffect } from 'react';
import { Button, Zoom } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Zoom in={isVisible}>
      <Button
        onClick={handleClick}
        sx={{
          position: 'fixed',
          bottom: 100,
          right: 16,
          zIndex: 1000,
        }}
      >
        <KeyboardArrowUp />
      </Button>
    </Zoom>
  );
};

export default ScrollTopButton;
