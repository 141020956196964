import React, { useState, useEffect } from 'react';

function DataConverter({ totalDataGB, usagePercentage }) {
  const [usedDataGB, setUsedDataGB] = useState(0); // Used data in GB
  const [usedDataMB, setUsedDataMB] = useState(0); // Used data in MB
  const parsedUsagePercentage = parseFloat(usagePercentage);

  // Calculate used data in GB
 
  const usedDataInMB = parsedUsagePercentage  / 100 * totalDataGB;
  const usedDataInGB = usedDataInMB / 1000; // Convert MB to GB

  let displayValue;
  let displayUnit;
  
  if (usedDataInMB < 1000) {
    displayValue = usedDataInMB.toFixed(2);
    displayUnit = 'MB';
  } else {
    displayValue = (usedDataInGB).toFixed(2);
    displayUnit = 'GB';
  }

  return (
    <span>
      {displayValue}  {displayUnit}
    </span>
  );
}

export default DataConverter;